import { combineReducers } from "redux";
import userProfile from "./modules/myaccount/store/userProfileSlice";
import businessSlice from "./modules/business/store/businessSlice";
import sharedSlice from "./modules/shared/store/sharedSlice";
import plansSlice from "./modules/billing/store/plansSlice";
import ordersSlice from "./modules/billing/store/ordersSlice";
import orderEntitySlice from "./modules/billing/store/orderEntitySlice";

export const rootReducer = combineReducers({
  userProfile: userProfile,
  business: businessSlice,
  sharedSlice: sharedSlice,
  plans : plansSlice,
  orders : ordersSlice,
  orderEntity : orderEntitySlice
});
