import "./App.css";
import "./assets/css/custom.css";
import "./assets/css/style.css";
import "./assets/css/frontend.css";
import { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser, verifyAccountDetails } from "./modules/shared/store/sharedSlice";
import { insertData } from "./modules/myaccount/store/userProfileSlice";
import { Spinner } from "react-bootstrap";
import CreateOrder from "./modules/billing/screens/CreateOrder";

const BusinessIndex = lazy(() =>
  import("./modules/business/screens/BusinessIndex")
);
const ProfileIndex = lazy(() =>
  import("./modules/myaccount/screens/ProfileIndex")
);
const BillIndex = lazy(() => import("./modules/billing/screens/BillIndex"));
const CurrentPlanBlock = lazy(() =>
  import("./modules/billing/component/CurrentPlanBlock")
);
const InvoiceBlock = lazy(() =>
  import("./modules/billing/component/InvoiceBlock")
);
const BillingAddressBlock = lazy(() =>
  import("./modules/billing/component/BillingAddressBlock")
);
const ChangePlanPage = lazy(() =>
  import("./modules/billing/screens/ChangePlanPage")
);

const InvoiceLayout = lazy(() =>
  import("./modules/billing/component/invoice/InvoiceLayout")
);

function App() {
  const dispatch = useDispatch();
  const [params, setParams] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const id = queryParams.get("id");
      const name = queryParams.get("name");
      const phone = queryParams.get("phone");
      const authenticateCode = queryParams.get("code");
      const currentTenant = queryParams.get("currentTenant");
      const email = queryParams.get("email");

      const userData = {
        user: {
          id: id,
          name: name,
          phone: phone,
          currentTenant: currentTenant,
          email: email == "null" ? "" : email,
        },
      };

      console.log();
      setParams({
        id: id,
        name: name,
        phone: phone,
        authenticateCode: authenticateCode,
        currentTenant: currentTenant,
        email: email,
      });
      dispatch(verifyAccountDetails({ code: authenticateCode }));
      dispatch(insertData(userData));
      dispatch(setUser(userData))

      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <div
      className="d-flex flex-column flex-root"
      style={{ height: "100vh", overflow: "hidden" }}
    >
      <BrowserRouter>
        <Suspense
          fallback={
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "100vh" }}
            >
              <Spinner animation="border" />
            </div>
          }
        >
          <Routes>
            <Route path="/" element={<BusinessIndex id={params} />} />
            <Route path="/business" element={<BusinessIndex id={params} />} />
            <Route
              path="myaccount"
              element={<ProfileIndex params={params} />}
            />
            <Route path="billing" element={<BillIndex params={params} />} />
            <Route path="currentPlan" element={<CurrentPlanBlock />} />
            <Route path="Invoice" element={<InvoiceBlock />} />
            <Route path="address" element={<BillingAddressBlock />} />
            <Route
              path="billing/change-plan"
              element={<ChangePlanPage userParams={params} />}
            />
            <Route path="create-order" element={<CreateOrder />} />
            <Route
              path="default/:id"
              element={<InvoiceLayout />}
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
