import SidebarComponent from "./sidebar/Sidebar";

const Layout = ({ children }) => {
  return (
    <div
      style={{
        overflowY: "hidden",
      }}
    >
      <div className="d-flex col-lg-12">
        <div className="">
          <SidebarComponent />
        </div>
        <div className="w-100">
          {" "}
          <div
            style={{
              overflowX: "hidden",
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
