import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { environment } from "../../shared/config/environment";
import axios from "axios";

const initialState = {
  user_id: null,
  tenant_id: null,
  subscription_id: null,
  grand_total: 0,
  tax_total: 0,
  taxable_value: 0,
  order_items: [
    {
      plan_id: null,
      quantity: 1,
      price: 0,
      tax_rate: 18,
      subtotal: 0,
    },
    {
      addon_id: null,
      quantity: 0,
      price: 0,
      tax_rate: 18,
      subtotal: 0,
    },
  ],
  order_billing_addresses: [
    {
      name:"",
      phone_number: "",
      address1: "",
      address2: "",
      city: "",
      pincode: "",
      state: "",
    },
  ],
  status: {
    success: false,
    error: false,
    errorMessage: "",
    successMessage: "",
  },
  addressSuccess: false,
};

export const createOrder = createAsyncThunk(
  "orderEntity/create",
  async (args, { rejectWithValue }) => {
    try {
      var config = {
        method: "POST",
        url: environment.URL + "tenants/orders",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${args.access_token}`,
        },
        data: args.data,
      };
      const orderData = await axios(config);
      return orderData?.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message);
    }
  }
);

const orderEntitySlice = createSlice({
  name: "orderEntity",
  initialState,
  reducers: {
    setPlan(state, action) {
      console.log("action payload for plan id", action.payload);
      state.order_items[0]["plan_id"] = action.payload.plan_id;
      state.order_items[0]["price"] = action.payload.price;
      console.log(
        "action payload for plan id",
        state.order_items[0]["plan_id"]
      );
    },
    setBillingAddress(state, action) {
      let { field, value } = action.payload;
      state.order_billing_addresses[0][field] = value;
    },
    setAddon(state, action) {
      state.order_items[1]["addon_id"] = action.payload.addon_id;
      state.order_items[1]["price"] = action.payload.price;
    },
    updateAddonQty(state, action) {
      state.order_items[1]["quantity"] = action.payload;
    },
    recalcTotals(state, action) {
      let orderItems = state.order_items;
      let grand_total = 0;
      let tax_total = 0;
      let taxable_value = 0;
      orderItems.map((value, index) => {
        let tempQty = value.quantity;
        let tempPrice = value.price;
        let tempTaxable = tempQty * tempPrice;
        let tax = tempTaxable * (value.tax_rate / 100);
        let subtotal = tempTaxable + tax;
        orderItems[index]["subtotal"] = subtotal;
        grand_total += subtotal;
        tax_total += tax;
        taxable_value += tempTaxable;
      });
      state.grand_total = grand_total;
      state.tax_total = tax_total;
      state.taxable_value = taxable_value;
    },
    updateAddressStaus(state, action) {
      state.addressSuccess = action.payload;
    },
    resetOrderEntity: () => initialState,
    resetBillingAddress(state, action) {
      state.order_billing_addresses = [
        {
          name:"",
          phone_number: "",
          address1: "",
          address2: "",
          city: "",
          pincode: "",
          state: "",
        },
      ]
    }
  },
  extraReducers(builder) {
    builder
      .addCase(createOrder.fulfilled, (state, action) => {
        state.status.success = true;
        state.status.successMessage = "Order created";
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.status.error = false;
        state.status.errorMessage = "Unable to place order";
      });
  },
});

export const {
  setPlan,
  resetOrderEntity,
  updateAddonQty,
  setAddon,
  setBillingAddress,
  recalcTotals,
  updateAddressStaus,
  resetBillingAddress
} = orderEntitySlice.actions;

export default orderEntitySlice.reducer;
