import { Form } from "react-bootstrap";
import { setBillingAddress } from "../../store/orderEntitySlice";
import { useDispatch, useSelector } from "react-redux";

const Address1 = () => {
  const dispatch = useDispatch();
  const { address1 } = useSelector(
    (state) => state?.orderEntity?.order_billing_addresses[0]
  );
  console.log("address 1", address1);
  return (
    <>
      <Form.Label>Address 1</Form.Label>
      <Form.Control
        name="address1"
        value={address1}
        as="textarea"
        rows={2}
        onChange={(e) => {
          let data = {
            field: "address1",
            value: e.target.value,
          };
          dispatch(setBillingAddress(data));
        }}
      />
    </>
  );
};

export default Address1;
