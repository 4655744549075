import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import { environment } from "../../shared/config/environment";
import axios from "axios";

const ordersAdapter = createEntityAdapter();

const initialState = ordersAdapter.getInitialState({
    status: false,
    error: null,
});


export const fetchOrders = createAsyncThunk("orders/fetch", async (args) => {
    try {
        console.log('fetch order args',args)
        var config = {
            method: "GET",
            url: environment.URL + `tenants/orders?tenant_id=`+args.tenantId,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${args.access_token}`,
            },
        };
        const response = await axios(config);
        console.log("orders fetch response", response);
        return response?.data?.data;
    } catch (error) {
        console.log("orders fetch error", error);
        throw error;
    }
});

const ordersSlice = createSlice({
    name: "orders",
    initialState,
    reducers: {
        resetOrders: () => initialState
    },
    extraReducers(builder) {
        builder
            .addCase(fetchOrders.fulfilled, (state, action) => {
                console.log('fetch orders payload',action.payload)
                state.status = true;
                ordersAdapter.upsertMany(state, action.payload);
            })
            .addCase(fetchOrders.rejected, (state, action) => {
                state.status = false;
                state.error = "Unable to fetch plans";
            })
    }
});

export default ordersSlice.reducer;

export const {
    resetPlans
} = ordersSlice.actions;

export const {
    selectAll: selectAllOrders,
    selectById: selectOrderById,
    selectIds: selectOrderIds,
  } = ordersAdapter.getSelectors((state) => state.orders);


