import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { environment } from "../../shared/config/environment";
import axios from "axios";
import { filter } from "lodash";

const plansAdapter = createEntityAdapter();

const initialState = plansAdapter.getInitialState({
  status: false,
  currentPlanStatus: false,
  error: null,
  currentPlanError: null,
  currentPlan: {},
  addons: [],
  period: "monthly",
});

export const fetchPlans = createAsyncThunk("plans/fetch", async (args) => {
  try {
    var config = {
      method: "GET",
      url: environment.URL + `tenants/plans`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${args.access_token}`,
      },
    };
    const response = await axios(config);
    console.log("plans fetch response", response);
    return response?.data?.data;
  } catch (error) {
    console.log("plans fetch error", error);
    throw error;
  }
});

export const fetchAddons = createAsyncThunk(
  "plans/fetchAddons",
  async (args) => {
    try {
      var config = {
        method: "GET",
        url: environment.URL + `tenants/addons`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${args.access_token}`,
        },
      };
      const response = await axios(config);
      console.log("plans fetch response", response);
      return response?.data?.data;
    } catch (error) {
      console.log("plans fetch error", error);
      throw error;
    }
  }
);

export const fetchCurrentPlanFeatures = createAsyncThunk(
  "plans/fetchCurrentPlanFeatures",
  async (args) => {
    console.log("arguments fetch plan", args);
    try {
      var config = {
        method: "GET",
        url: environment.URL + `tenants/plan-features/` + args.tenantId,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${args.access_token}`,
        },
      };
      const response = await axios(config);
      console.log("current plan response for api data", response);
      return response?.data;
    } catch (error) {
      console.log("plans fetch error", error);
      throw error;
    }
  }
);

const plansSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {
    selectedPeriod(state, action) {
      state.period = action.payload;
    },
    resetPlans: () => initialState,
  },

  extraReducers(builder) {
    builder
      .addCase(fetchPlans.fulfilled, (state, action) => {
        state.status = true;
        plansAdapter.upsertMany(state, action.payload);
      })
      .addCase(fetchPlans.rejected, (state, action) => {
        state.status = false;
        state.error = "Unable to fetch plans";
      })
      .addCase(fetchCurrentPlanFeatures.fulfilled, (state, action) => {
        state.currentPlanStatus = true;
        state.currentPlan = action.payload;
      })
      .addCase(fetchCurrentPlanFeatures.rejected, (state, action) => {
        state.currentPlanStatus = false;
        state.currentPlanError = "Unable to fetch plans";
      })
      .addCase(fetchAddons.fulfilled, (state, action) => {
        state.status = true;
        state.addons = action.payload;
      })
      .addCase(fetchAddons.rejected, (state, action) => {
        state.status = false;
        state.error = "Unable to fetch addons";
      });
  },
});

export default plansSlice.reducer;

export const { resetPlans,selectedPeriod } = plansSlice.actions;

export const {
  selectAll: selectAllPlans,
  selectById: selectPlanById,
  selectIds: selectPlanIds,
} = plansAdapter.getSelectors((state) => state.plans);

export const selectPlansByPeriod = createSelector(
  [selectAllPlans, (state, period) => period],

  (plans, period) => {
    console.log("period", period);
    return filter(plans, { period: period });
  }
);
