import { Button, Col, Modal, Row } from "react-bootstrap";
import Address1 from "../component/fields/Address1";
import Address2 from "../component/fields/Address2";
import PhoneNumber from "../component/fields/PhoneNumber";
import Pincode from "../component/fields/Pincode";
import State from "../component/fields/State";
import City from "../component/fields/City";
import { resetBillingAddress, setBillingAddress, updateAddressStaus } from "../store/orderEntitySlice";
import { useDispatch, useSelector } from "react-redux";
import Name from "../component/fields/Name";
import { useEffect } from "react";

const BillingAddress = ({ show, setShow, isEdit }) => {
  const user = useSelector((state) => state?.sharedSlice?.user?.user)
  console.log("user details fetching", user);
  const dispatch = useDispatch();

  useEffect(() => {
    let data = {
      field: "name",
      value: user?.name,
    };
    dispatch(setBillingAddress(data))
  }, [])
  useEffect(() => {
    let data = {
      field: "phone_number",
      value: user?.phone,
    };
    dispatch(setBillingAddress(data))
  }, [])
  const handleSave = () => {
    dispatch(updateAddressStaus(true));
  };
  return (
    <>
      <Modal
        size="lg"
        show={show}
        keyboard={true}
        onHide={() => {
          if (isEdit) {
            setShow(false);
          } else {
            dispatch(resetBillingAddress());
            setShow(false);
          }
        }}
        dialogClassName="modal-dialog modal-dialog-centered"
      >
        <Modal.Header closeButton>
          <Modal.Title>Billing Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="">
            <Col lg={6} className="">
              <Name />
            </Col>
            <Col lg={6}></Col>
          </Row>
          <Row className="mt-3">
            <Col lg={12} >
              <Address1 />
            </Col>

          </Row>
          <Row className="mt-3">
            <Col lg={12} >
              <Address2 />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg={3}>
              <PhoneNumber />
            </Col>
            <Col lg={3}>
              <City />
            </Col>
            <Col lg={3}>
              <Pincode />
            </Col>
            <Col lg={3}>
              <State />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ borderTop: "1px solid lightgray" }}>
          <Row className=" py-1">
            {" "}
            <Col className="d-flex justify-content-end">
              {" "}
              <Button
                id="submitButton"
                className="mx-3"
                variant="primary"
                onClick={handleSave}
              >
                Save
              </Button>
              <Button
                variant="outline-secondary"
                onClick={() => {

                  if (isEdit) {
                    setShow(false);
                  } else {
                    dispatch(resetBillingAddress());
                    setShow(false);
                  }
                }
                }
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BillingAddress;
