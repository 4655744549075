import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { environment } from "../config/environment";
import axios from "axios";

const initialState = {
  userData: {},
  error: "",
  status: false,
  tenants: [],
  loading: false,
  currentTenant : {},
  user:{}
};

export const verifyAccountDetails = createAsyncThunk(
  "manageAccount/verifyAccount",
  async (args, { rejectWithValue }) => {
    const data = {
      authenticate_code: args?.code,
    };
    try {
      var config = {
        method: "POST",
        url: environment.URL + `authentication/get-verifycode`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      const response = await axios(config);
      console.log("response data", response);
      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchAllTenants = createAsyncThunk(
  "sharedSlice/fetchTenant",
  async (id, { rejectWithValue }) => {
    console.log("args", id);
    try {
      var config = {
        method: "GET",
        url: environment.URL + `authentication/get-tenants/${id}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      console.log("response fetch account", response);
      return response.data;
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error);
    }
  }
);

const SharedSlice = createSlice({
  name: "sharedSlice",
  initialState,
  reducers: {
    setCurrentTenant(state,action){
      state.currentTenant = action.payload;
    },
    setUser(state,action){
      state.user = action.payload
    },
    resetSharedSlice : () => initialState
  },
  extraReducers(builder) {
    builder
      .addCase(verifyAccountDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(verifyAccountDetails.fulfilled, (state, action) => {
        console.log("fetch sale action", action.payload);
        state.status = true;
        state.loading = false;
        state.userData = action.payload;
        state.tenants = action.payload?.tenants;
      })
      .addCase(verifyAccountDetails.rejected, (state, action) => {
        console.log("fetch sale action", action.payload);
        state.loading = false;
        state.error = action.payload?.message ? action.payload?.message : "";
      })
      .addCase(fetchAllTenants.fulfilled, (state, action) => {
        state.tenants = action.payload?.tenants;
      })
      .addCase(fetchAllTenants.rejected, (state, action) => {
        console.log("tenants get error", action.payload.message);
      })
  },
});

export  const { setCurrentTenant, resetSharedSlice,setUser } = SharedSlice.actions;

export default SharedSlice.reducer;
