import { Col, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { placeOfSupplyList } from "../../shared/config/placeOfSupply";

const BillingAddressModal = ({ show, setShow }) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #ccc",
      borderRadius: "4px",
      boxShadow: state.isFocused ? "0 0 7px rgb(77,144,254,0.5)" : null,
      height: "30px",
    }),
  };
  return (
    <Modal
      size="lg"
      show={show}
      keyboard={true}
      onHide={() => {
        setShow(false);
      }}
      dialogClassName="modal-dialog modal-dialog-centered"
    >
      <Modal.Header closeButton>
        <Modal.Title>Address Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="">
          <Col lg={12}>
            <Form.Group>
              <Form.Label>Billing Address</Form.Label>
            </Form.Group>
          </Col>
          <Col lg={12}>
            <Form.Control
              as="textarea"
              placeholder="Enter billing address"
              name="address"
              className="form-control"
              // value={values.address}
              rows={2}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg={4}>
            <Form.Group>
              <Form.Label>City</Form.Label>
            </Form.Group>
            <Form.Control
              name="city"
              placeholder="Enter City"
              //value={values.city}
            />
          </Col>
          <Col lg={4}>
            <Form.Group>
              <Form.Label>Pincode</Form.Label>
            </Form.Group>
            <Form.Control
              name="pincode"
              placeholder="Enter Pincode"
              //value={values.pincode}
              maxLength={6}
            />
          </Col>
          <Col lg={4}>
            <Form.Label>State</Form.Label>
            <Select
              name="state"
              options={placeOfSupplyList}
              styles={customStyles}
              //   value={placeOfSupplyList.find(
              //     (option) => option.value === values.state
              //   )}
              //   onChange={(selectedOption) => {
              //     setFieldValue("state", selectedOption);
              //     dispatch(changeState(selectedOption.value));
              //   }}
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default BillingAddressModal;
