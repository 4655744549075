import { Form } from "react-bootstrap";
import { placeOfSupplyList } from "../../../shared/config/placeOfSupply";
import { useDispatch, useSelector } from "react-redux";
import { setBillingAddress } from "../../store/orderEntitySlice";
import Select from "react-select";

const State = () => {
  const dispatch = useDispatch();
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #ccc",
      borderRadius: "4px",
      boxShadow: state.isFocused ? "0 0 7px rgb(77,144,254,0.5)" : null,
      height: "30px",
    }),
  };

  const { state } = useSelector(
    (state) => state?.orderEntity?.order_billing_addresses[0]
  );
  console.log("state", state);
  return (
    <>
      <Form.Label>State</Form.Label>
      <Select
        name="state"
        options={placeOfSupplyList}
        styles={customStyles}
        value={placeOfSupplyList.find(
          (option) => option.label == state
        )}
        onChange={(selectedOption) => {
            console.log("selected option",selectedOption)
          let data = {
            field: "state",
            value: selectedOption.label,
          };
          dispatch(setBillingAddress(data));
        }}
      />
    </>
  );
};

export default State;
