import { useLocation } from "react-router";
import { Sidebar, Menu, MenuItem, sidebarClasses } from "react-pro-sidebar";
import SideBarHeader from "./SidebarHeader";
import { UilBag, UilUserCircle } from "@iconscout/react-unicons";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

const SidebarComponent = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isActive = (path) => location.pathname === path;

  return (
    <div>
      <Sidebar
        width="230px"
        rootStyles={{
          [`.${sidebarClasses.container}`]: {
            backgroundColor: "white",
            height: "100%",
            paddingLeft: "5px",
            paddingRight: "5px",
            boxShadow: "3px 0.125rem 0.25rem rgba(6, 9, 23, 0.075)",
          },
        }}
      >
        <SideBarHeader />
        <div
          className="col-lg-12 mt-2 "
          style={{
            height: "calc(100vh - 10px)",
            overflowY: "auto",
          }}
        >
          <Menu
            menuItemStyles={{
              button: ({ level, disabled, active }) => {
                console.log("active", active);
                if (level === 0)
                  return {
                    backgroundColor: active ? "#e9ecef" : "white",
                    borderRadius: "10px",
                    // transition: "0.5s ease",
                    ":hover": {
                      borderRadius: "10px",
                    },
                    fontSize: "13px",
                    whiteSpace: "nowrap",
                    height: "35px",
                    opacity: 1,
                    fontWeight: 400,
                  };
              },
            }}
          >
            <MenuItem
              //onClick={() => dispatch(resetUser())}
              className="mb-2"
              active={isActive("/business") || isActive("/")}
              component={<Link to="/business" />}
            >
              <UilBag className="mx-2" size="18" />
              My Businesses
            </MenuItem>
            <MenuItem
              className="mb-2"
              active={isActive("/myaccount")}
              component={<Link to="/myaccount" />}
            >
              <UilUserCircle className="mx-2" size="18" />
              My Account
            </MenuItem>
          
          </Menu>
        </div>
      </Sidebar>
    </div>
  );
};

export default SidebarComponent;
