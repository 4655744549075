import {  Card, Col, Container, Form, Row } from "react-bootstrap";
import BillingAddressModal from "./BillingAddressModal";
import { useState } from "react";
import { useSelector } from "react-redux";

const BillingAddressBlock = () => {
  const { order_billing_addresses } = useSelector((state) => state.orderEntity);
  const [show, setShow] = useState(false);

  return (
    <Container fluid className="px-2">
      {order_billing_addresses && order_billing_addresses.length > 0 ? (
        <Card className="border" style={{ borderRadius: "5px" }}>
          <Row className="p-4">
            <Col lg={8}>
              <Form.Group>
                <Form.Label style={{ fontSize: "18px", fontWeight: 600 }}>
                  Billing Address
                </Form.Label>
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group>
                <Form.Label style={{ fontSize: "14px" }}>
                  {order_billing_addresses[0].address1},&nbsp;{order_billing_addresses[0].address2},&nbsp;{order_billing_addresses[0].city},&nbsp;
                  {order_billing_addresses[0].state} - {order_billing_addresses[0].pincode}
                </Form.Label>
              </Form.Group>
            </Col>
          </Row>
        </Card>
      ) : null}
      {show && <BillingAddressModal show={show} setShow={setShow} />}
    </Container>
  );
};

export default BillingAddressBlock;
