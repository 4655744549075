import { Form } from "react-bootstrap";
import { setBillingAddress } from "../../store/orderEntitySlice";
import { useDispatch, useSelector } from "react-redux";

const Address2 = () => {
    const dispatch = useDispatch();
    const { address2 } = useSelector(
      (state) => state?.orderEntity?.order_billing_addresses[0]
    );
    console.log("address 2", address2);
  return (
    <>
     <Form.Label>Address 2</Form.Label>
     <Form.Control
        name="address2"
        value={address2}
        as="textarea"
        rows={2}
        onChange={(e) => {
          let data = {
            field: "address2",
            value: e.target.value,
          };
          dispatch(setBillingAddress(data));
        }}
      />
    </>
  );
};

export default Address2;
