import { Col, Image, Row } from "react-bootstrap"
import bill from '../../../../assets/images/logo6-small.png'


const SideBarHeader = () => {
    return(
        <Row>
            <Col lg={12} className="d-flex justify-content-center py-4" style={{ borderBottom:'1px solid lightgray'}}>
                <Image src={bill} height={50} />
            </Col>
        </Row>
    )
}

export default SideBarHeader;