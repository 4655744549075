import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setBillingAddress } from "../../store/orderEntitySlice";
import { numValidate } from "../../../shared/helpers/numberValidate";

const Pincode = () => {
  const dispatch = useDispatch();
  const { pincode } = useSelector(
    (state) => state?.orderEntity?.order_billing_addresses[0]
  );
  console.log("pincode", pincode);
  return (
    <>
      <Form.Label>Pincode</Form.Label>
      <Form.Control
        maxLength={6}
        name="pincode"
        type="text"
        value={pincode}
        onKeyPress={(event) => numValidate(event)}
        onChange={(e) => {
          let data = {
            field: "pincode",
            value: e.target.value,
          };
          dispatch(setBillingAddress(data));
        }}
      />
    </>
  );
};

export default Pincode;
