import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setBillingAddress } from "../../store/orderEntitySlice";

const City = () => {
  const dispatch = useDispatch();
  const { city } = useSelector(
    (state) => state?.orderEntity?.order_billing_addresses[0]
  );
  console.log("city", city);
  return (
    <>
      <Form.Label>City</Form.Label>
      <Form.Control
        name="city"
        value={city}
        onChange={(e) => {
          let data = {
            field: "city",
            value: e.target.value,
          };
          dispatch(setBillingAddress(data));
        }}
      />
    </>
  );
};

export default City;
