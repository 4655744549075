import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { environment } from "../../shared/config/environment";
import axios from "axios";

const initialState = {
  name: "",
  error: "",
  status: false,
  success: "",
  updateData: {},
  deleteStatus:false
};

export const updateBusiness = createAsyncThunk(
  "business/update",
  async (args, { rejectWithValue }) => {
    console.log("fetch setting params", args);
    try {
      var config = {
        method: "PUT",
        url: environment.URL + "authentication/update-tenant",
        headers: {
          "Content-Type": "application/json",
          "X-tenant": args.id,
        },
        data: args.data,
      };
      const businessData = await axios(config);
      console.log("businessData data", businessData);
      return businessData?.data;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message);
    }
  }
);

export const deleteBusiness = createAsyncThunk(
  "business/delete",
  async (id, { rejectWithValue }) => {
    console.log("fetch args params", id);
    try {
      var config = {
        method: "DELETE",
        url: environment.URL + `authentication/delete-tenant/${id}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const deleteData = await axios(config);
      console.log("deleteData data", deleteData);
      return deleteData;
    } catch (err) {
      return rejectWithValue(err?.response?.data?.message);
    }
  }
);

const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    changeName(state, action) {
      state.name = action.payload;
    },
    resetBusiness(state, action) {
      state.name = "";
      state.status = false;
      state.error = "";
      state.success = "";
      state.deleteStatus = false
    },
  },
  extraReducers(builder) {
    builder
      .addCase(updateBusiness.fulfilled, (state, action) => {
        console.log("update business payload", action.payload);
        state.status = true;
        state.success = "Business updated successfully..!";
        state.updateData = action.payload;
        state.deleteStatus = false
      })
      .addCase(updateBusiness.rejected, (state, action) => {
        state.status = false;
        state.error =
          "Error while update business name, please try again later";
      })
      .addCase(deleteBusiness.fulfilled, (state, action) => {
        state.status = true;
        state.deleteStatus = true
        state.success = "Business deleted successfully..!";
      })
      .addCase(deleteBusiness.rejected, (state, action) => {
        state.status = false;
        state.error = "Error while delete business, please try again later";
      });
  },
});

export const { changeName, resetBusiness } = businessSlice.actions;

export default businessSlice.reducer;
