import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { environment } from "../../shared/config/environment";

const initialState = {
  data: {},
  name: null,
  email: null,
  otp: "",
  phone: "",
  newPhone: "",
  otpData: {},
  status: false,
  error: "",
  otpMessage: "",
  otpStatus: false,
  verifyMessage: "",
  errorStatus:false
};

export const fetchOtp = createAsyncThunk("userProfile/getOtp", async (data) => {
  console.log("environment", environment.URL);
  try {
    var config = {
      method: "POST",
      url: environment.URL + "auth/user-profile",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    const response = await axios(config);
    console.log("edit setting response", response);

    return response.data;
  } catch (error) {
    throw error;
  }
});

export const editUserName = createAsyncThunk(
  "userProfile/editUserName", async (data, { rejectWithValue }) => {
    try {
      var config = {
        method: "PUT",
        url: environment.URL + `auth/update-username`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      const response = await axios(config);
      console.log("edit setting response", response);

      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
)

export const verifyEmail = createAsyncThunk(
  "userProfile/verifyEmail",
  async (data, { rejectWithValue }) => {
    try {
      var config = {
        method: "PUT",
        url: environment.URL + `auth/user-verify`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      const response = await axios(config);
      console.log("edit setting response", response);

      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const verifyPhone = createAsyncThunk(
  "userProfile/verifyPhone",
  async (data, { rejectWithValue }) => {
    try {
      var config = {
        method: "PUT",
        url: environment.URL + `auth/user-verify`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      const response = await axios(config);
      console.log("edit setting response", response);

      return response.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const userSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    updateName(state, action) {
      state.name = action.payload;
    },
    updateEmail(state, action) {
      console.log("name", action.payload);
      state.email = action.payload;
    },
    updateOtp(state, action) {
      console.log("otp", action.payload);
      state.otp = action.payload;
    },
    updatePhone(state, action) {
      state.newPhone = action.payload;
    },
    chanageNewPhone(state, action) {
      state.newPhone = action.payload;
    },
    initUpdate(state, action) {
      console.log("init update", action.payload);
      state.name = action.payload.name
      state.email = action.payload?.email;
      state.phone = action.payload.phone;
    },
    resetStatus(state, action) {
      state.status = false;
      state.otpStatus = false;
      state.otpMessage = "";
      state.verifyMessage = "";
    },
    resetError(state, action) {
      state.error = "";
      state.errorStatus = false;
    },
    insertData(state, action) {
      console.log("insert data payload", action.payload);
      state.data = action.payload;
    },
    resetUser: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOtp.fulfilled, (state, action) => {
        console.log("action.payload otp", action.payload);
        state.otpData = action.payload;
        state.otpStatus = true;
        state.otpMessage = action.payload.message;
      })
      .addCase(fetchOtp.rejected, (state, action) => {
        console.log("error message", action.payload);
        state.error = "Error while fetch otp ,Please try again later";
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = true;
        state.verifyMessage = "Email updated successfully";
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        console.log("error message", action.payload);
        state.status = false;
        state.errorStatus = true;
        state.error = action.payload.error;
      })
      .addCase(verifyPhone.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = true;
        state.verifyMessage = "Phone updated successfully";
      })
      .addCase(verifyPhone.rejected, (state, action) => {
        console.log("error message", action.payload);
        state.status = false;
        state.errorStatus = true;
        state.error = action.payload.error;
      })
      .addCase(editUserName.fulfilled, (state, action) => {
        console.log("error message", action.payload);
        state.data = action.payload;
        state.status = true;
        state.verifyMessage = action.payload.message;
      })
      .addCase(editUserName.rejected, (state, action) => {
        console.log("error message", action.payload);
        state.status = false;
        state.error = action.payload.error;
      })
  },
});

export const {
  updateName,
  updateEmail,
  updateOtp,
  updatePhone,
  initUpdate,
  resetStatus,
  resetUser,
  chanageNewPhone,
  resetError,
  insertData,
} = userSlice.actions;

export default userSlice.reducer;
