import {  Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setBillingAddress } from "../../store/orderEntitySlice";

const Name = () => {
  const dispatch = useDispatch();
  const { name } = useSelector(
    (state) => state?.orderEntity?.order_billing_addresses[0]
  );
  console.log("name", name);
  return (
    < >
      <Form.Label>Name</Form.Label>
      <Form.Control
        name="name"
        value={name}
        onChange={(e) => {
          let data = {
            field: "name",
            value: e.target.value,
          };
          dispatch(setBillingAddress(data));
        }}
      />
    </>
  );
};

export default Name;
