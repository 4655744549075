import { Badge, Button, Card, Col, Container, Form, ListGroup, ListGroupItem, Row, Stack, } from "react-bootstrap";
import Layout from "../../shared/components/Layout";
import Header from "../../shared/components/Header";
import { useNavigate, useSearchParams, } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAddons, selectPlanById, selectPlansByPeriod, } from "../store/plansSlice";
import { useEffect, useState } from "react";
import { createOrder, recalcTotals, resetOrderEntity, setAddon, setPlan, updateAddonQty, } from "../store/orderEntitySlice";
import { cloneDeep } from "lodash";
import BillingAddress from "./BillingAddress";
import BillingAddressBlock from "../component/BillingAddressBlock";
import { UilEdit } from "@iconscout/react-unicons";

const CreateOrder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const selectedPlan = searchParams.get("selected");
  console.log("search plan", selectedPlan);
  const { access_token } = useSelector((state) => state.sharedSlice.userData);
  const planDetails = useSelector((state) =>
    selectPlanById(state, selectedPlan)
  );
  const { addons } = useSelector((state) => state.plans);
  const yearlyPlans = useSelector((state) =>
    selectPlansByPeriod(state, "yearly")
  );
  const { order_items, grand_total, tax_total, taxable_value, status, order_billing_addresses } =
    useSelector((state) => state.orderEntity);
  const { currentTenant } = useSelector((state) => state.sharedSlice);
  console.log("order_items", order_items);

  const orderEntity = useSelector((state) => state.orderEntity);

  const { addressSuccess } = useSelector((state) => state.orderEntity);
  const { currentPlan } = useSelector((state) => state.plans);

  const [show, setShow] = useState(false);
  const [addlUsers, setAddlUsers] = useState(0);
  const [finalUsersCount, setFinalUsersCount] = useState(0);
  const [isEdit, setIsEdit] = useState(false)
  const userAddonPrice = addons[0]?.price;

  useEffect(() => {
    if (addressSuccess) {
      // toast.success("Billing Address Added Successfully..!");
      setShow(false);
      setIsEdit(false)

    }
  }, [addressSuccess]);

  const handleBilling = () => {
    setShow(true);
  };

  useEffect(() => {
    dispatch(
      fetchAddons({
        access_token: access_token,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      setAddon({
        addon_id: addons[0]?.id,
        price: addons[0]?.price,
      })
    );
  }, [addons]);

  useEffect(() => {
    dispatch(
      setPlan({
        plan_id: planDetails?.id,
        price: planDetails?.price,
      })
    );
  }, [planDetails]);

  const handleNoOfUsersChange = (qty) => {
    dispatch(updateAddonQty(qty));
  };

  const handleAddlUsersChange = (qty) => {
    setAddlUsers(qty);
  };

  useEffect(() => {

    let finalQty = 0;

    let includedUsers = parseInt(planDetails?.max_users);
    let currentUsers = parseInt(currentPlan?.subscription?.current_users);
    if (addlUsers) {
      finalQty = (currentUsers + parseInt(addlUsers)) - includedUsers;
    }
    setFinalUsersCount(finalQty);
    dispatch(updateAddonQty(finalQty));
  }, [addlUsers])

  useEffect(() => {
    let finalQty = 0;
    let includedUsers = parseInt(planDetails?.max_users);
    let currentUsers = parseInt(currentPlan?.subscription?.current_users);
    finalQty = currentUsers - includedUsers;
    setFinalUsersCount(finalQty);
    dispatch(updateAddonQty(finalQty));

  }, [])


  useEffect(() => {
    dispatch(recalcTotals());
  }, [order_items]);

  useEffect(() => {
    if (status) {
      if (status.success) {
        alert(status.successMessage);
        navigate("/billing");
        dispatch(resetOrderEntity());
      } else if (status.error) {
        alert(status.errorMessage);
        navigate("/billing");
        dispatch(resetOrderEntity());
      }
    }
  }, [status]);

  const handleCreateOrder = () => {
    let orderData = cloneDeep(orderEntity);
    console.log("order data", orderData);
    delete orderData.status;
    // delete orderData.order_billing_addresses;
    orderData.user_id = currentTenant?.pivot?.user_id;
    orderData.tenant_id = currentTenant?.id;
    orderData.subscription_id = currentPlan?.subscription?.id;
    if (
      orderData.order_billing_addresses &&
      orderData.order_billing_addresses.length > 0
    ) {
      const firstAddress = orderData.order_billing_addresses[0];
      const isEmptyAddress = Object.values(firstAddress).every(
        (value) => value === ""
      );

      if (isEmptyAddress) {
        delete orderData.order_billing_addresses;
      }
    }

    console.log("order data", orderData);

    dispatch(
      createOrder({
        access_token: access_token,
        data: orderData,
      })
    );
  };


  const handleEdit = () => {
    setIsEdit(true)
  }

  const total = order_items[1]?.quantity * addons[0]?.price;

  return (
    <Layout>

      <Header
        title={"Change Billplus plan"}
        ItemActions={
          <Stack>
            <Button
              variant="outline-secondary"
              onClick={() => {
                navigate("/billing/change-plan");
              }}
            >
              Back
            </Button>
          </Stack>
        }
      />
      <Container
        className=""
        fluid
        style={{
          height: "calc(100vh - 80px)",
          overflow: "auto",
          overflowX: "hidden",
        }}
      >
        {!Object.values(order_billing_addresses[0]).some(value => value !== "") && !addressSuccess && <Col lg={12}>
          <Button variant="link px-0" onClick={handleBilling}>
            + Add Billing Address
          </Button>
        </Col>}
        {Object.values(order_billing_addresses[0]).some(value => value !== "") && addressSuccess && <Col lg={12}>
          <Button variant="link px-0 " onClick={handleEdit}>
            <UilEdit size="16" className="mx-2" />
            Edit
          </Button>
        </Col>}
        {Object.values(order_billing_addresses[0]).some(value => value !== "") && addressSuccess && <BillingAddressBlock />}
        <Row className="mt-2">
          <Col md={8}>
            <Card className="">
              <Card.Header className="border h4">
                Plan
              </Card.Header>

              <Card.Body className="p-2 border">
                <Card.Title className="text-center text-success"></Card.Title>
                <ListGroup variant="flush">
                  <ListGroupItem>
                    <div className="d-flex justify-content-between">
                      <div>
                        <Badge
                          className=""
                          bg="success"
                          style={{ fontSize: "20px" }}
                        >
                          {planDetails?.label}
                        </Badge>
                        <span className="mx-3">
                          ₹&nbsp;{planDetails?.price} /{" "}
                          {planDetails?.period == "monthly" ? "Month" : "Year"}
                        </span>
                      </div>
                      <div>
                        <Button
                          variant="link float-end pe-0"
                          onClick={() => {
                            navigate("/billing/change-plan");
                          }}
                        >
                          Change
                        </Button>
                      </div>
                    </div>
                  </ListGroupItem>
                  <ListGroupItem
                    className="justify-content-between"
                    style={{ textTransform: "capitalize" }}
                    as={Stack}
                    gap={4}
                    direction="horizontal"
                  >
                    <div>
                      <span>Free Users In Plan(A) </span>

                    </div>
                    <span className="d-flex align-items-center">
                      {planDetails?.max_users}
                    </span>
                  </ListGroupItem>

                  <ListGroupItem
                    className="justify-content-between"
                    style={{ textTransform: "capitalize" }}
                    as={Stack}
                    gap={4}
                    direction="horizontal"
                  >
                    <div>
                      <span>Current Users(B) </span>

                    </div>
                    <span className="d-flex align-items-center">
                      {currentPlan?.subscription?.current_users}
                    </span>
                  </ListGroupItem>




                  {/* <ListGroupItem
                        className=""
                        style={{ textTransform: "capitalize" }}
                      ></ListGroupItem> */}
                </ListGroup>
              </Card.Body>
              <Card.Footer className="border">
                Total <span className="float-end">₹&nbsp;{planDetails?.price}</span>
              </Card.Footer>
            </Card>

            <Card className="mt-5">
              <Card.Header className="border h4">
                Addons
              </Card.Header>

              <Card.Body className="p-2 border">
                <Card.Title className="text-center text-success"></Card.Title>
                <ListGroup variant="flush">



                  <ListGroupItem
                    className="justify-content-between"
                    style={{ textTransform: "capitalize" }}
                    as={Stack}
                    gap={4}
                    direction="horizontal"
                  >
                    <div>
                      <span>Additional Users(C) </span>
                      (₹&nbsp;{userAddonPrice} / User)
                    </div>
                    <span className="d-flex align-items-center">
                      {" "}
                      <Form.Control
                        type="number"
                        className="mx-2 text-center"
                        style={{ width: "100px" }}
                        onChange={(e) => handleAddlUsersChange(e.target.value)}
                      />

                    </span>
                  </ListGroupItem>

                  <ListGroupItem
                    className="justify-content-between"
                    style={{ textTransform: "capitalize" }}
                    as={Stack}
                    gap={4}
                    direction="horizontal"
                  >
                    <div>
                      <span>Paid Users( B + C - A) </span>

                    </div>
                    <span className="d-flex align-items-center">
                      {finalUsersCount}
                    </span>
                  </ListGroupItem>
                  {/* <ListGroupItem
                        className=""
                        style={{ textTransform: "capitalize" }}
                      ></ListGroupItem> */}
                </ListGroup>
              </Card.Body>
              <Card.Footer className="border">
                Total : ₹&nbsp;{userAddonPrice} X {finalUsersCount} <span className="float-end">₹&nbsp;{total}</span>
              </Card.Footer>
            </Card>
          </Col>

          <Col md={4}>
            <Card className="">
              <Card.Header
                className={`text-white`}
                style={{ background: "#00796B" }}
              >
                Summary
              </Card.Header>

              <Card.Body className="p-2 border">
                <Card.Title className="text-center text-success"></Card.Title>
                <ListGroup variant="flush">
                  <ListGroupItem
                    className=""
                    style={{ textTransform: "capitalize" }}
                  >
                    Total{" "}
                    <span className="float-end">₹&nbsp;{taxable_value}</span>
                  </ListGroupItem>
                  <ListGroupItem
                    className=" "
                    style={{ textTransform: "capitalize" }}
                  >
                    Tax<span className="float-end">₹&nbsp;{tax_total}</span>
                  </ListGroupItem>
                  <ListGroupItem
                    className="h4"
                    style={{ textTransform: "capitalize" }}
                  >
                    Grand total{" "}
                    <span className="float-end">₹&nbsp;{grand_total}</span>
                  </ListGroupItem>
                </ListGroup>
              </Card.Body>
              <Card.Footer className="border h3 p-3">
                <span>
                  <Button
                    variant="primary float-end"
                    onClick={handleCreateOrder}
                  >
                    Proceed to pay
                  </Button>
                </span>
              </Card.Footer>
            </Card>
          </Col>
        </Row>

        {show && <BillingAddress show={show} setShow={setShow} />}
        {isEdit && <BillingAddress show={isEdit} setShow={setIsEdit} isEdit={true} />}
      </Container>
    </Layout>
  );
};

export default CreateOrder;
