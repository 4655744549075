export const placeOfSupplyList = [
    { value: 1, label: "Jammu And Kashmir" },
    { value: 2, label: "Himachal Pradesh" },
    { value: 3, label: "Punjab" },
    { value: 4, label: "Chandigarh" },
    { value: 5, label: "Uttarakhand" },
    { value: 6, label: "Haryana" },
    { value: 7, label: "Delhi" },
    { value: 8, label: "Rajasthan" },
    { value: 9, label: "Uttar Pradesh" },
    { value: 10, label: "Bihar" },
    { value: 11, label: "Sikkim" },
    { value: 12, label: "Arunachal Pradesh" },
    { value: 13, label: "Nagaland" },
    { value: 14, label: "Manipur" },
    { value: 15, label: "Mizoram" },
    { value: 16, label: "Tripura" },
    { value: 17, label: "Meghalaya" },
    { value: 18, label: "Assam" },
    { value: 19, label: "West Bengal" },
    { value: 20, label: "Jharkhand" },
    { value: 21, label: "Odisha" },
    { value: 22, label: "Chhattisgarh" },
    { value: 23, label: "Madhya Pradesh" },
    { value: 24, label: "Gujarat" },
    { value: 25, label: "Daman And Diu (old)" },
    { value: 26, label: "Dadra & Nagar Haveli and Daman & Diu" },
    { value: 27, label: "Maharashtra" },
    { value: 28, label: "Andhra Pradesh (Old)" },
    { value: 29, label: "Karnataka" },
    { value: 30, label: "Goa" },
    { value: 31, label: "Lakshadweep" },
    { value: 32, label: "Kerala" },
    { value: 33, label: "Tamil Nadu" },
    { value: 34, label: "Puducherry" },
    { value: 35, label: "Andaman And Nicobar islands" },
    { value: 36, label: "Telangana" },
    { value: 37, label: "Andhra Pradesh" },
    { value: 38, label: "Ladakh" },
    { value: 97, label: "Other Territory" },
  ];
