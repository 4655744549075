import { Container, Stack } from "react-bootstrap";

const Header = ({ title, ItemActions }) => {
  return (
    <Container
      fluid
      className="mb-3 d-flex flex-column align-items-center justify-content-center"
      style={{
        height: "67px",
        background: "white",
       // boxShadow: "1.0px 0.5px 7.0px hsl(0deg 0% 0% / 0.38)",
       borderBottom:'1px solid lightgray'
      }}
    >
      <Stack
        direction="horizontal"
        className="d-flex justify-content-between px-0"
      >
        <Stack
          direction="horizontal"
          className="d-flex justify-content-start align-items-center p-0"
        >
          <h2 className="mt-3 bold ">{title}</h2>
        </Stack>

        <Stack direction="horizontal" className="">
          {ItemActions}
        </Stack>
      </Stack>
    </Container>
  );
};

export default Header;
